<template>
  <!-- eslint-disable-next-line vue/no-v-html vue/html-self-closing -->
  <svg v-bind="svg?.attributes" :width="props.width" :height="props.height" v-html="svg?.contents"></svg>
</template>

<script setup>
const props = defineProps({
  src: {
    type: String,
    default: null
  },
  width: {
    type: [String, Number],
    default: null
  },
  height: {
    type: [String, Number],
    default: null
  }
})

function parseSvg (svg) {
  const regex = /<svg\s*(?<attr>.*?)>(?<contents>.*?)<\/svg>/s
  const matches = svg.match(regex)
  const { contents, attr } = matches.groups

  const attributes = {}
  attr.match(/([a-zA-Z]+=".*?")/gs).forEach((value) => {
    const attr = value.match(/(?<name>[a-zA-Z]+)="(?<value>.*)"/).groups
    attributes[attr.name] = attr.value
  })

  Object.keys(attributes).forEach((key) => {
    if (props[key] && ['width', 'height'].includes(key)) {
      attributes[key] = props[key]
    }
  })

  return { contents, attributes }
}

const { data: svg } = await useFetch(props.src, {
  responseType: 'text',
  transform: parseSvg,
  default: () => {
    return {
      attributes: { width: props.width ?? 1, height: props.height ?? 1 },
      contents: ''
    }
  }
})

</script>
